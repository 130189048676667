export type IconType = keyof typeof iconsSrcMap

export const iconsSrcMap = {
  wallet: { name: "BannerWallet.svg" },
  chat: { name: "BannerChat.svg" },
  delivery: { name: "BannerDelivery.svg" },
  pharmacists: { name: "BannerPharmacists.svg" },
  box: { name: "BannerBox.svg" },
  deliveryTime: { name: "BannerDeliveryTime.svg" },
  comment: { name: "Comment.svg" }
}
