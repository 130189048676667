<script setup lang="ts">
import type { PaymentProps } from "./Payment.props"
import { iconsSrcMap } from "./Payment.iconMapping"

const props = withDefaults(defineProps<PaymentProps>(), {
  isCentered: false,
  backgroundColor: "tiffany-10"
})

const useDifferentColors = computed(
  () => props.backgroundColor === "yellow-main"
)
</script>

<template>
  <div
    class="banner-delivery-payment_delivery flex items-center gap-6 p-4"
    :class="[{ 'py-6 md:justify-center': isCentered }, `bg-${backgroundColor}`]"
  >
    <div
      class="relative h-10 w-10 shrink-0 rounded-full md:h-14 md:w-14"
      :class="[useDifferentColors ? 'bg-white' : 'bg-tiffany-30']"
    >
      <UtilsIcon
        v-bind="iconsSrcMap[iconType]"
        v-if="iconType"
        class="absolute -bottom-2 -right-3 h-8 w-auto md:h-12"
      />
    </div>

    <div class="flex h-full flex-col justify-center">
      <UtilsMarkdown
        v-if="title"
        class="pig-bold md:dolphin-bold lg:pig-bold mb-2 text-black-main"
        :content="title"
      />

      <UtilsMarkdown
        v-if="text"
        class="beaver markdown__link-underlined markdown__link-beaver-medium"
        :class="[useDifferentColors ? 'text-black-main' : 'text-black-80']"
        :content="text"
      />
    </div>
  </div>
</template>
